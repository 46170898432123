<template>
  <div class="thank-you-page">
    <v-container class="pt-5">
      <v-card flat class="elevation-0 white w-100 rounded pa-4">
        <CategoryTitle :category="category" />
        <v-card-actions
          class="justify-end"
          v-if="showLogin && !isAuthenticated"
        >
          <div class="d-flex justify-end">
            <v-btn @click="handleLogin" color="primary" large depressed block>
              Accedi ora
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>

      <RecaptchaDisclaimer @click="clicked" />
    </v-container>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";

import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import loginMixins from "~/mixins/login";

import UserService from "~/service/userService";
// import Login from "@/components/Login.vue";

export default {
  name: "RegisterConfirmed",
  components: { CategoryTitle, RecaptchaDisclaimer },
  props: { showLogin: { type: Boolean, default: false } },
  data() {
    return { errors: [], infos: [], confirmed: false };
  },
  mixins: [categoryMixins, loginMixins, clickHandler],
  methods: {
    async handleLogin() {
      if (await this.doLogin("RegistrationConfirmed")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            this.$router.push({
              name: "Home",
              path: "/"
            });
          }
        }
      }
    },
    async doConfirm(email, controlCode) {
      let response = await UserService.confirmRegistration(email, controlCode);

      this.errors = response.errors;
      this.infos = response.infos;
      this.confirmed = response.status == 0;
    }
  },
  created() {
    if (this.$route.query.email) {
      this.doConfirm(this.$route.query.email, this.$route.query.token);
    }
  }
};
</script>

<style lang="scss">
.thank-you-page {
  .recaptcha-disclaimer {
    margin-top: 50px;
  }
}
</style>
